'use strict';

module.exports = function() {

	$('.search').each(function() {
		var $search = $(this);
		var $btn = $search.find('.search__btn');
		var $entry = $search.find('.search__entry');

		$btn.on('click', function(event) {
			if (!$search.hasClass('active')) {
				$search.addClass('active');
				$entry.focus();

				event.preventDefault();
			}
		});

		$(document).on('click', function(event) {
			if (!event.target.closest('.search.active')) {
				$search.removeClass('active');
			}
		});
	});

};