"use strict";
var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");


var initCustomWidgets = function () {
	require('./modules/mobileDetect')();
	require('./modules/slideTo')();
	require('./modules/promo')();
	require('./modules/nav')();
	require('./modules/search')();
};

$(initCustomWidgets);
