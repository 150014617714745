'use strict';

require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.promo__slider').each(function() {
		var $slider = $(this);
		var slidesQuantity = $slider.find('.swiper-slide').length;

		if (slidesQuantity > 1) {
			$slider.after('<div class="promo__pagination"></div>');

			$slider.swiper({
				pagination: '.promo__pagination',
				paginationClickable: true,
				loop: true,
				autoplay: 5000,
				bulletClass: 'promo__bullet',
				bulletActiveClass: 'promo__bullet_active'
			});
		}
	});

};