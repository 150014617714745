'use strict';

module.exports = function () {
	
	$('.slide-to').on('click', function() {
		var $target = $($(this).attr('href'));

		$('html,body').animate({
			scrollTop: $target.offset().top
		}, 500);

		return false;
	});

};