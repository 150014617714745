'use strict';

module.exports = function () {
	
	$('.nav').each(function() {
		var $nav = $(this);
		var $toggle = $nav.find('.nav__toggle');

		$toggle.on('click', function() {
			$nav.toggleClass('nav_active');
		});

		$(document).on('click', function(event) {
			if (!event.target.closest('.nav_active')) {
				$nav.removeClass('nav_active');
			}
		});
	})

};